<template>
  <!-- 图例 -->
  <div>
    <img
      v-if="legendType === 'current'"
      src="@/assets/image/map/weather/current.png"
      alt=""
    />
    <img
      v-if="legendType === 'fvcom'"
      src="@/assets/image/map/weather/fvcom.png"
      alt=""
    />
    <img
      v-if="legendType === 'temp'"
      src="@/assets/image/map/weather/temp.png"
      alt=""
    />
    <img
      v-if="legendType === 'wave'"
      src="@/assets/image/map/weather/wave.png"
      alt=""
    />
    <img
      v-if="legendType === 'wind'"
      src="@/assets/image/map/weather/wind.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Legend",
  components: {},
  props: {
    legendType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
